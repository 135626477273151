*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
    font-family: 'Roboto Mono', monospace;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    z-index: -1;
}

.container{
    position: fixed;
    top: 0;
    bottom: 0;
    width: min(1800px, 100vw);
    z-index: 1;

    display: flex;
    align-items: center;
    margin: 0 auto;

    padding-inline: 2rem;
}

.box{
    display: flex;
    flex-direction: column;
}

.subtitle,
.title{
    color: white;
    display: inline-block;
}


.title{
    font-weight: 300;
    font-size: 3vw;
}

.subtitle{
    font-size: 2.15vw;
    font-weight: 300;

}

.links{
    margin-top: .3rem;
}

.links a{
    color: white;
    text-decoration: none;
    box-shadow: 0px 1px white;
}

@media (max-width: 600px) {
    .title{
        font-size: 8vw;
    }
    .subtitle{
        font-size: 5.8vw;
    
    }
    .box{
        display: flex;
        flex-direction: column;
        background: rgb(232,166,166);
        background: linear-gradient(180deg, rgba(232,166,166,0) 0%, rgba(0,0,0,1) 75%);
        padding: 1rem 2rem;
        align-self: flex-end;
        width: 100%;
    }

    .container{
        padding-inline: 0;
    }
}
